/*
* create ajax button function
* */

$(function () {
    dataImageUpload.init();
});

var dataImageUpload = (() => {
    var $btn = null,
        _link = null,
        $body = $('body');

    var start = () => {
        $body
            .on('change','[data-type-upload="upload"]', function () {
                uploadFile($(this));

                return false;
            })
        ;

    };


    var uploadFile = (that) => {
        //$btn.attr('disabled','disabled');
        var formData = new FormData(),
            _frm = that.parents('form'),
            _url = that.attr('data-url') || null,
            _name = that.attr('data-name-upload');

        formData.append(_name, that[0].files[0]);
        formData.append('_method', that.attr('data-method-upload') || 'patch');
        formData.append('_token', _frm.children('[name="_token"]').val() || '');
        formData.append('image-type', _name);

        axios.post(_url, formData)
            .then(response => {
                _frm.find('.upload-card-' + _name).children('.img').removeClass('d-none');
                var _img = _frm.find('[data-image="' + _name + '"]');
                if (_img.is('[src]')) {
                    _img.attr('src', response.data.image);
                } else if (_img.is('[href]')) {
                    _img.attr('href', response.data.image);
                }
              window.showMessage('Upload successful');
            })
            .catch( ( error ) => {
                console.log(error.response.data);

                window.showErrors(error.response);
            });
    };

    return {
        init: () => {
            start();
        }
    }
})();
