/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Loaded before CoreUI app.js
import '../bootstrap';
import 'pace';
import '../plugins';

import 'air-datepicker';
import 'select2';
import './modules/helpers';
import './modules/datapicker';
import './modules/boxes';
import './modules/settings';
import './modules/ajaxImageUpload';
import './modules/ajaxButtons';
import './modules/apartments';
