var Sortable = require('sortablejs');
$(() => {
    dataBoxBlocks.init();
    dataBoxBlocks.redactors($('[data-box-redactor]'));
});

var changedBtns = [];

var dataBoxBlocks = (() => {
    var $wrap = $('#blocksContent');
    var $inner = null;
    var sortable = null;
    var innSortable = null;
    var block_id = null;
    var _token = $('[name="csrf-token"]').attr('content');

    var start = () => {
        if (!$wrap.length) {
            return false
        }
        block_id = parseInt($wrap.data('block_id'));
        $('body')
            .on('click', 'button[data-box="collapse"]', function () {
                collapse($(this));
            })
            .on('click', 'button[data-box="sort-toggle"]', function () {
                sortToggle($(this));
            })
            .on('click', 'button[data-box="add-box"]', function () {
                addNewBox($(this));
            })
            .on('click', 'button[data-box="save"]', function () {
                saveBoxData($(this));
            })
            .on('click', 'button[data-box="delete"]', function () {
                deleteBox($(this));
            })
            .on('click', 'button[data-box="moder"]', function () {
                moderBox($(this));
            })
            .on('click', 'button[data-box="add-new-stat"]', function () {
                addNewStatInBox($(this));
            })
            .on('click', 'button[data-box="box-stat-remove"]', function () {
                removeStatInBox($(this));
            })
            .on('click', 'button[data-box="inner-sort"]', function () {
                innerSortToggle($(this));
            })
            .on('click', 'button[data-box="save-all"]', function () {
                _.forEach(changedBtns, function (btn) {
                    btn.click();
                });
                changedBtns = [];
                $('[data-box="save-all"]').fadeOut(400);
            })
            .on('change', '[data-box="parent"] [name]', function () {
                var $inp = $(this);
                var $prnt = $inp.closest('[data-box="parent"]');

                if ($inp.attr('type') === 'file') {
                    $prnt.find('[data-box="save"]').click();
                } else {
                    $('[data-box="save-all"]').fadeIn(400);
                    changedBtns.push($prnt.find('[data-box="save"]'));
                    console.log('Изменилось поле', $inp);
                }
            })
            .on('blur', '[data-box="parent"] .redactor-box .redactor-styles', function () {
                var $inp = $(this);
                var $prnt = $inp.closest('[data-box="parent"]');

                $('[data-box="save-all"]').fadeIn(400);
                changedBtns.push($prnt.find('[data-box="save"]'));
                console.log('Изменилось поле', $inp);
            })
        ;

        getBoxes();

    };

    var initSelect2 = ($elements) => {
        $elements.each(function (i,el) {
            $(el).select2({
                width: '100%'
            });
        });
    };
    var initRedactor = ($needRedactors, focused) => {
        setTimeout(()=>{
            $needRedactors.each(function (i,red) {
                // $R(red);
                var $red = $(red);
                var type = $red.attr('data-box-redactor') || 'default';
                var settings = {
                    lang: 'ru',
                    focus: !!focused,
                    air: false,
                };
                if(type === 'default'){
                    settings['buttons'] = ['html', 'undo', 'redo', 'format', 'image', 'file', 'link', 'ul', 'ol'];
                    settings['formatting'] = ['p', 'blockquote', 'h2', 'h3', 'h4', 'h5', 'h6'];
                    // settings['plugins'] = ['video','file-upload','filemanager','imagemanager','specialchars'];
                    // settings['fileUpload'] = '/admin/files/upload/?_token=' + _token;
                    // settings['fileManagerJson'] = '/admin/files/all.json';
                    // settings['imageUpload'] = '/admin/images/upload/?_token=' + _token;
                    // settings['imageManagerJson'] = '/admin/images/all.json';
                    // settings['imageFigure'] = true;
                } else if(type === 'light') {
                    settings['buttons'] = ['undo', 'redo', 'link', 'ul'];
                    settings['plugins'] = ['specialchars'];
                } else if(type === 'links') {
                    settings['buttons'] = ['undo', 'redo', 'link'];
                    settings['plugins'] = ['specialchars'];
                } else if(type === 'bold') {
                    settings['buttons'] = ['link','bold'];
                    settings['plugins'] = ['specialchars'];
                }
                $R(red, settings);
                // console.log($(red).redactor(settings));

            });
        }, 5);
    };

    var getBoxes = () => {
        axios
            .get('/admin/block/' + block_id)
            .then(function (response) {
                var html = (!_.isUndefined(response.data.html)) ? response.data.html : '';
                $wrap.html(html);
                setTimeout(() => {
                    $inner = $wrap.find('#blocksContentInner');
                    initRedactor($inner.find('[data-box-redactor]'));
                    initSelect2($inner.find('.select2'));
                    $wrap.find('[data-toggle="tooltip"]').tooltip();
                }, 0);
            })
            .catch(function (error) {
                window.showErrors(error.response.request);
            })
            .then(function () {
                console.log('remove loader');
            })
    };

    var removeStatInBox = ($btn) => {
        var $prnt = $btn.closest('[data-box="box-stat-one"]');
        $prnt.fadeOut(400, function () {
            $prnt.remove();
        })
    };

    var addNewStatInBox = ($btn) => {
        var $prnt = $btn.closest('[data-box="parent-for-new"]');
        var $boxOne = ($prnt.length) ? $prnt : $btn.closest('[data-box="parent"]');
        var $items = $boxOne.find('[data-box="box-stat-all"]');
        var _temp = $boxOne.find('[data-box="stat-template"]').html();
        var _lKey = parseInt($items.find('[data-stat-key]:not([data-stat-key="NEW_KEY"]):last').attr('data-stat-key')) || 0;

        _temp = _temp.replace(/NEW_KEY/gi, (_lKey+1));
        $items.append(_temp);
    };

    var saveBoxData = ($btn) => {
        var $boxOne = $btn.closest('[data-box="parent"]');
        var $content = $boxOne.find('[data-box="content"]');
        var item_id = parseInt($boxOne.data('id'));
        var $form = $('<form enctype="multipart/form-data" />');

        // var formData = {};
        var formData = new FormData();
        $content.find('[name]').each(function (i, _inp) {
            var $inp = $(_inp).clone();
            var name = $(_inp).attr('name') || '';
            var type = $(_inp).attr('type') || 'text';
            var val = $(_inp).val() || '';
            if (type === 'file') {
                _.forEach($inp[0].files, function (file, i) {
                    formData.append(name + '[' + i + ']', file);
                });
            } else if (type === 'checkbox') {
                if ($inp.prop('checked')) {
                    formData.append(name, true);
                }
            } else if (name !== '' && val !== '') {
                formData.append(name, val);
            }
        });
        console.log($form,formData);

        window.ldrBtn.s($btn);
        axios
            .post('/admin/block/' + block_id + '/item/' + item_id + '/edit', formData)
            .then(function (response) {
                console.log(response);
                var html = (!_.isUndefined(response.data.html)) ? response.data.html : false;
                let message = (!_.isUndefined(response.data.message)) ? response.data.message : '';
                if(html) {
                    let $newHtml = $('<div/>').append(html).find('[data-box="content"]');
                    let $content = $boxOne.find('[data-box="content"]');
                    $content.replaceWith($newHtml);

                    window.showMessage(message);

                    initRedactor($boxOne.find('[data-box-redactor]'));
                    initSelect2($boxOne.find('.select2'));
                }else{
                    window.showErrors('');
                }
                // let html = (!_.isUndefined(response.data.html)) ? response.data.html : '';
                // $inner.html(html);
            })
            .catch(function (error) {
                window.showErrors(error.response.request);
            })
            .then(function () {
                window.ldrBtn.h($btn);
            })
    };

    var deleteBox = ($btn) => {
        let $prnt = $btn.closest('[data-box="parent"]');
        let _id = parseInt($prnt.attr('data-id'));
        Swal.fire({
            title: "Вы уверены?",
            showCancelButton: true,
            confirmButtonText: "Удалить",
            cancelButtonText: "Отмена",
            type: 'warning'
        }).then((result) => {
            if (!!!result.value) {
                return false;
            }
            window.ldrBtn.s($btn);
            axios
                .delete('/admin/block/' + block_id + '/item/' + _id + '/remove')
                .then(function (response) {
                    $prnt.find('[data-box="collapse"]').click();
                    setTimeout(()=>{
                        $prnt.slideUp(400, function () {
                            $prnt.remove();
                        })
                    }, 100);
                })
                .catch(function (error) {
                    window.showErrors(error.response.request);
                })
                .then(() => {
                    window.ldrBtn.h($btn);
                });
        });

    };

    var moderBox = ($btn) => {
        alert('moderBox');
    };

    var addNewBox = ($btn) => {
        let type = $btn.attr('data-add-box-type') || false;
        if(!type) return;
        window.ldrBtn.s($btn);
        axios
            .post('/admin/block/' + block_id + '/item/add',{
                'type': type
            })
            .then(function (response) {
                let html = (!_.isUndefined(response.data.html)) ? response.data.html : '';
                $inner.append(html);
                setTimeout(()=>{
                    let $last = $inner.find('.box:last');
                    // $last.find('[data-box="collapse"]').click();
                    initRedactor($last.find('[data-box-redactor]'), true);
                },5);
            })
            .catch(function (error) {
                window.showErrors(error.response.request);
            })
            .then(() => {
                window.ldrBtn.h($btn);
            });
    };

    var innerSortToggle = ($btn) => {
        let isSort = ($btn.attr('data-sort-enable') === 'true');
        let $prnt = $btn.closest('[data-box="parent"]');
        let $innWrap = $prnt.find('[data-inner-sort]');
        if (isSort) {
            $innWrap.removeClass('sorting');
            innSortable.destroy();
            innSortable = null;
        } else {
            if (innSortable) {
                innSortable.destroy();
                innSortable = null;
            }
            $wrap.find('[data-sort-enable]').attr('data-sort-enable', false);
            $wrap.find('[data-inner-sort].sorting').removeClass('sorting');
            innSortable = Sortable.create($innWrap[0]);
            $innWrap.addClass('sorting');
        }
        $btn.attr('data-sort-enable', !isSort);
    };

    var sortToggle = ($btn) => {
        $wrap.find('.box[data-box]').each((i, el) => {
            boxShow($(el));
        });
        if (sortable) {
            let ids = sortable.toArray();
            saveSortedBoxes($btn, ids, function () {
                sortable.destroy();
                sortable = null;
                $wrap.attr('data-sorted', 'false');
                $wrap.find('[data-box="collapse"]').removeAttr('disabled');
            });
        } else {
            sortable = Sortable.create($inner[0]);
            $wrap.attr('data-sorted', 'true');
            $wrap.find('[data-box="collapse"]').attr('disabled', 'disabled');
        }
    };

    var saveSortedBoxes = ($btn, ids, clbk) => {
        console.log('saveSortedBoxes: ', ids);
        window.ldrBtn.s($btn);
        axios
            .post('/admin/block/' + block_id + '/sort',{
                'ids': ids
            })
            .then(function () {
                clbk();
            })
            .catch(function (error) {
                window.showErrors(error.response.request);
            })
            .then(() => {
                window.ldrBtn.h($btn);
            });
    };

    var boxEdit = ($box) => {
        $box.attr('data-editing', 'true');
    };
    var boxShow = ($box) => {
        $box.attr('data-editing', 'false');
    };

    var collapse = ($btn) => {
        let $box = $btn.closest('[data-box="parent"]');
        let state = ($box.attr('data-editing') === 'true');
        if (state) {
            boxShow($box);
        } else {
            boxEdit($box);
        }
    };

    return {
        init: () => {
            start();
        },
        redactors: (data) => {
            initRedactor(data);
        }
    }
})();



