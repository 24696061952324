/*
* create ajax button function
* */

$(function () {
  dataApartments.init();
});

const dataApartments = (() => {
  const start = () => {
    $('body')
      .on('click', '[data-house-popup]', function () {
        const $btn = $(this);
        const link = $btn.attr('data-house-popup') || null;

        if (link) {
          getForm(link, $btn);
          $('.tooltip.show').removeClass('show');
          setTimeout(() => {
            $('.tooltip').remove();
          }, 250);
        }

        return false;
      })
      .on('click', '[data-house-remove]', function () {
        const $btn = $(this);
        const link = $btn.attr('data-house-remove') || null;

        if (link) {
          Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (!!!result.value) {
              return false;
            }

            axios({
              method: 'DELETE',
              url: link
            }).then((response)=>{
              if (!_.isUndefined(response.data.tableHtml)) {
                const tableName = response.data.tableName;
                $(`[data-house-table="${tableName}"]`).replaceWith(response.data.tableHtml);
              }
            })

          });
        }

        return false;
      })
      .on('change, input', '[data-house-location]', function () {
        const _val = $(this).val();

        $('[data-location-rotate]').css({
          'transform': `rotate(${_val}deg)`
        });

        return false;
      })
      .on('submit', '[data-house-form]', function (e) {
        e.preventDefault();
        const $form = $(this);
        const type = $form.attr('data-house-form') || null;

        sendForm($form, type);

        return false;
      })
      .on('click', '[data-house-type="cancel"]', function () {
        setTimeout(() => {
          Swal.close();
        }, 0);

        return false;
      })
      .on('click', '[data-house-show-canvas]', function () {
        const $btn = $(this);
        const $inp = $btn.prev();
        const koors = JSON.parse($inp.val());
        const $prnt = $btn.closest('[data-house-parent]');
        const $canvas = $prnt.find('[data-house-canvas]');
        const cW = $canvas.innerWidth();
        const cH = $canvas.innerHeight();
        var stage = new Konva.Stage({
          container: $canvas.attr('id'),
          width: cW,
          height: cH,
        });

        const floor_id = $btn.attr('data-house-floor-btn');
        if (floor_id) {
          $('[data-house-plan-floor]').hide();
          $(`[data-house-plan-floor="${floor_id}"]`).show();
          $('[data-house-floor-btn]').removeClass('active');
          $btn.addClass('active');
        }
        const apart_id = $btn.attr('data-house-apart-btn');
        if (apart_id) {
          $('[data-house-apart-btn]').removeClass('active');
          $btn.addClass('active');
        }

        const layer = new Konva.Layer();
        const points = [];
        const anchors = [];

        koors.forEach(koor => {
          const x = cW * (koor.x/100);
          const y = cH * (koor.y/100);
          points.push(x);
          points.push(y);
        });
        const poly = new Konva.Line({
          points: points,
          fill: '#00D2FF',
          stroke: 'black',
          strokeWidth: 1,
          closed: true,
          opacity: 0.35
        });
        const updatePoly = () => {
          let points = [];
          for (let i = 0; i < anchors.length; i++) {
            points = [...points, anchors[i].x(), anchors[i].y()];
          }
          poly.points(points);
          layer.batchDraw();
          saveKoors();
        };
        const saveKoors = () => {
          let newKoors = [];
          anchors.forEach(anchor => {
            const kX = Math.round((anchor.x() / cW) * 10000) / 100;
            const kY = Math.round((anchor.y() / cH) * 10000) / 100;
            newKoors.push({x: kX, y: kY});
          });
          $inp.val(JSON.stringify(newKoors));
          console.log('new koors: ', $inp.val());
        };
        const addNewAnchor = (x,y) => {
          const anchor = new Konva.Circle({
            x: x,
            y: y,
            radius: 6,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 1,
            draggable: true,
            opacity: 0.5
          });
          anchors.push(anchor);
          anchor.on('dragmove', () => updatePoly());
          anchor.on('mouseover', function () {
            $canvas.css('cursor', 'move');
            this.stroke('#D20000');
            layer.draw();
          });
          anchor.on('mouseout', function () {
            $canvas.css('cursor', 'default');
            this.stroke('black');
            layer.draw();
          });
          layer.add(anchor);
        };
        // add the shape to the layer
        layer.add(poly);
        //
        for (let i = 0; i <= points.length - 2; i = i + 2) {
          addNewAnchor(points[i], points[i + 1]);
        }

        const btnMouseDown = function (type) {
          if(type === 'add') {
            const lastAnchor = anchors.length > 0 ? anchors[anchors.length-1] : null;
            const newX = !lastAnchor ? cW/2 : lastAnchor.x() > 25 ? lastAnchor.x() - 20 : 5;
            const newY = !lastAnchor ? cH/2 : lastAnchor.y() > cH - 10 ? cH - 10 : lastAnchor.y() + 10;
            addNewAnchor(newX, newY);
            updatePoly();
          }
          if(type === 'remove') {
            if (!anchors.length) return;
            anchors[anchors.length - 1].remove();
            anchors.pop();
            updatePoly();
          }
        };

        const buttons = [
          { type: 'add', text: 'Add ⚬', x: cW - 190, y: 10, w: 70 },
          { type: 'remove', text: 'Remove ⚬', x: cW - 110, y: 10, w: 100}
        ];
        const textOptions = {
          align: 'center', fontSize: 15, lineHeight: 1.6, fontFamily: 'Calibri', fill: '#73818f',
        };
        const btnOptions = {
          stroke: '#73818f', strokeWidth: 1, fill: '#e4e7ea', height: 24,
          shadowColor: 'black', shadowBlur: 5, shadowOffsetX: 2, shadowOffsetY: 2, shadowOpacity: 0.15,
        };

        for (let i=0, l=buttons.length; i < l; i++) {
          const params = buttons[i];
          const btnText = new Konva.Text({...textOptions,
            width: params.w,
            x: params.x,
            y: params.y,
            text: params.text,
          });
          const btnBack = new Konva.Rect({...btnOptions,
            width: params.w,
            x: params.x,
            y: params.y,
          });
          btnText.on('mouseover', function (text, btn) {
            $canvas.css('cursor', 'pointer');
            btnText.fill('#5c6873');
            btnBack.stroke('#5c6873');
            btnBack.shadowOpacity(0.25);
            layer.draw();
          });
          btnText.on('mouseout', function () {
            $canvas.css('cursor', 'default');
            btnText.fill('#73818f');
            btnBack.stroke('#73818f');
            btnBack.shadowOpacity(0.15);
            layer.draw();
          });
          btnText.on('mousedown', () => btnMouseDown(params.type));
          layer.add(btnBack);
          layer.add(btnText);
        }

        // add the layer to the stage
        stage.add(layer);

        return false;
      })
    ;

  };


  const sendForm = ($form, formType) => {
    const $submit = $form.find('button[type="submit"]');
    const action = $form.attr('action');
    const method = $form.attr('method');
    const formData = new FormData($form[0]);
    const namesArr = [];

    $form.find('[name]').each(function (i, el) {
      namesArr.push($(el).attr('name'));
      $(el)
        .removeClass('is-invalid')
        .next('small').remove();
    });
    $submit.attr('disabled', 'disabled');

    axios({
      method: method,
      url: action,
      data: formData
    })
      .then((response) => {
        if (!_.isUndefined(response.data.tableHtml)) {
          const tableName = response.data.tableName;
          $(`[data-house-table="${tableName}"]`).replaceWith(response.data.tableHtml);
        }
        Swal.close();
        if (!_.isUndefined(response.data.link)) {
          getForm(response.data.link, null);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        const errs = error.response.data.errors || false;
        let isFormError = false;

        if (!!errs) {
          _.forEach(errs, (err, name) => {
            const errText = _.join(err);
            if (_.indexOf(namesArr, name) > -1) {
              const $inp = $form.find('[name="' + name + '"]');
              $inp
                .addClass('is-invalid')
                .after('<small class="invalid-feedback">' + errText + '</small>');
              isFormError = true;
            }
          })
        }
        if (!isFormError) {
          Swal.close();
          window.showErrors(error.response.data);
        }
      })
      .then(() => {
        $submit.removeAttr('disabled');
      });
    return false;
  };


  const getForm = (link, $btn) => {
    if ($btn) {
      $btn.attr('disabled', 'disabled');
    }

    axios.get(link)
      .then((response) => {
        Swal.fire({
          html: response.data,
          width: '1000px',
          showCancelButton: false,
          showConfirmButton: false,
          onOpen: () => {
            window.reInitPickDateTime();
          }
        });
      })
      .catch((error) => {
        window.showErrors(error.response.data);
      })
      .then(() => {
        if ($btn) {
          $btn.removeAttr('disabled');
        }
      });
  };

  return {
    init: () => {
      start();
    }
  }
})();
