const moment = require('moment');

const initPickDateTime = () => {
  $('.pickDateTime').each((i, el) => {
    const $dp = $(el);
    let needDate = false;

    if($dp.attr('data-date') !== '') {
      needDate = moment($dp.attr('data-date'))._d;
    }

    if (_.isUndefined($dp.attr('data-date'))) {
      $dp
        .datepicker({
          timepicker: true,
          dateFormat: 'yyyy-mm-dd',
          timeFormat: 'hh:ii',
        });
    } else if (needDate) {
      $dp.datepicker({
          timepicker: true,
          inline: true,
          dateFormat: 'yyyy-mm-dd',
          timeFormat: 'hh:ii',
          startDate: needDate,
        })
        .data('datepicker').selectDate(needDate);
    } else {
      $dp
        .datepicker({
          timepicker: true,
          inline: true,
          dateFormat: 'yyyy-mm-dd',
          timeFormat: 'hh:ii',
        });
    }
  })
};

window.reInitPickDateTime = initPickDateTime;
$(() => {
  initPickDateTime();
});
