$(() => {
    settings.init();
});

var settings = (() => {
    var start = () => {
        $('body')
            .on('change', '[data-settings="file-auto-upload"] [name]', function () {
                var $inp = $(this);
                var $prnt = $inp.closest('[data-settings="parent"]');

                if ($inp.attr('type') === 'file') {
                    fileSave($inp);
                }
            })
            .on('click', 'button[data-settings="add-new-stat"]', function () {
                addNewStat($(this));
            })
            .on('click', 'button[data-settings="save-block-data"]', function () {
                saveData($(this));
            })
            .on('click', 'button[data-settings="setting-stat-remove"]', function () {
                removeOneStatElem($(this));
            })
            .on('click', 'button[data-slug]', function () {
                let $btn = $(this);
                let $from = $($btn.data('from'));
                let $to = $($btn.data('to'));
                if ($from.length && $to.length) {
                    if ($from.val() !== '') {
                        $to.val(slugify($from.val()));
                    }
                }
            })
    };

    var fileSave = (file) => {
        var formData = new FormData();

        formData.append('file_key', file.prop('name'));
        formData.append(file.prop('name'), file[0].files[0], file[0].files[0].name);

        axios
            .post('/admin/settings/file', formData)
            .then(function (response) {
                var parent = file.parent('.form-control'),
                    newLink = document.createElement('a')
                    oldLink = parent.children('a'),
                    textNode = document.createTextNode(response.data.link);;

                newLink.setAttribute('target', '_blank');
                newLink.setAttribute('href', response.data.link);
                newLink.appendChild(textNode);

                oldLink.remove();

                parent.prepend(newLink);
            })
            .catch(function (error) {
                window.showErrors(error.response.request);
            });
    };

    var addNewStat = ($btn) => {
        var $prnt = $btn.closest('[data-settings="parent-for-new"]');
        var $boxOne = ($prnt.length) ? $prnt : $btn.closest('[data-settings="parent"]');
        var $items = $boxOne.find('[data-settings="settings-stat-all"]');
        var _temp = $boxOne.find('[data-settings="stat-template"]').html();
        var _lKey = parseInt($items.find('[data-stat-key]:not([data-stat-key="NEW_KEY"]):last').attr('data-stat-key')) || 0;

        _temp = _temp.replace(/NEW_KEY/gi, (_lKey+1));
        $items.append(_temp);
    };

    var saveData = ($btn) => {
        var $prnt = $btn.closest('[data-settings="parent-for-new"]')
            formData = new FormData();

        $prnt.find('[name]').each(function (i, _inp) {
            var $inp = $(_inp).clone();
            var name = $(_inp).attr('name') || '';
            var type = $(_inp).attr('type') || 'text';
            var val = $(_inp).val() || '';

            if (type === 'file') {
                _.forEach($inp[0].files, function (file, i) {
                    formData.append(name, file);
                });
            } else if (name !== '' && val !== '') {
                formData.append(name, val);
            }
        });

        window.ldrBtn.s($btn);
        axios
            .post('/admin/settings/data', formData)
            .then(function (response) {
                if (response.data.html != '') {
                    $prnt.html(response.data.html);
                }
            })
            .catch(function (error) {
                window.showErrors(error.response.request);
            })
            .then(function () {
                window.ldrBtn.h($btn);
            });
    };

    var removeOneStatElem = ($btn) => {
        var $prnt = $btn.closest('[data-settings="settings-stat-one"]');

        $prnt.remove();
    };

    var slugify = (str) => {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
        var from = [
            'а', 'б', 'в', 'г', 'д', 'е', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф',
            'х', 'ц', 'ч', 'ш','щ', 'ъ', 'ь', 'ю', 'я'];
        var to = [
            'a', 'b', 'v', 'g', 'd', 'e', 'zh', 'z', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f',
            'h', 'c', 'ch', 'sh','sht', 'y', '', 'yu', 'ya'];
        for (var key in from) {
            str = str.replace(new RegExp(from[key], 'g'), to[key])
        }
        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-') // collapse dashes
          .replace(/-$/, ''); // remove last -

        return str;
    };


    return {
        init: () => {
            start();
        }
    }
})();
