/*
* create ajax button function
* */

$(function () {
    dataAjaxButtons.init();
});

var dataAjaxButtons = (() => {
    var $btn = null,
        _link = null,
        $body = $('body');

    var start = () => {
        $body
            .on('click','[data-ajax_create]', function () {
                $btn = $(this);
                _link = $btn.attr('data-ajax_create') || null;

                getForm();
                return false;
            })
            .on('click','[data-ajax_moder]', function () {
                sendModer($(this));
                return false;
            })
            .on('submit','[data-ajax_form]', function (e) {
                var that = this;

                sendForm(that);
                e.preventDefault();
                return false;
            })
            .on('click','[data-ajax_form] [data-ajax_type="cancel"]', function () {
                var $form = $(this).closest('form[data-ajax_form]');
                setTimeout(()=> {
                    Swal.close();
                }, 0);
                return false;
            })
        ;

    };


    var sendModer = ($mdr) => {
        var action = $mdr.attr('href') || null;
        var $prnt = $mdr.parent();
        if(action) {
            $('.tooltip').remove();
            $mdr.attr('disabled', 'disabled');

            axios.get(action)
                .then((response) => {
                    if (!_.isUndefined(response.data.html)) {
                        $prnt.html(response.data.html);
                        setTimeout(() => {
                            $prnt.find('.btn').tooltip('show');
                        }, 50);
                    }
                })
                .catch((error) => {
                    window.showErrors(error.response.data);
                });
        }
    };


    var sendForm = (that) => {
        var $frm = $(that);
        var $submit = $frm.find('button[type="submit"]'),
            action = $frm.attr('action'),
            method = $frm.attr('method'),
            formData = new FormData(that),
            namesArr = [];

        $frm.find('[name]').each(function (i,el) {
            var name = $(el).attr('name');
            namesArr.push(name);
            $(el)
                .removeClass('is-invalid')
                .next('small').remove();
        });
        $submit.attr('disabled','disabled');

        axios.post(action, formData)
            .then((response) => {
                if (!_.isUndefined(response.data.link)) {
                    window.location.href = response.data.link;
                } else {
                    console.log(response.data);
                }
                Swal.close();
            })
            .catch((error) => {
                var errs = error.response.data.errors || false;
                var isFormError = false;

                if (!!errs) {
                    _.forEach(errs, (err, name) => {
                        var errText = _.join(err);
                        if (_.indexOf(namesArr, name) > -1) {
                            var $inp = $frm.find('[name="' + name + '"]');
                            $inp
                                .addClass('is-invalid')
                                .after('<small class="invalid-feedback">' + errText + '</small>');
                            isFormError = true;
                        }
                    })
                }
                if (!isFormError) {
                    Swal.close();
                    window.showErrors(error.response.data);
                }
            })
            .then(() => {
                $submit.removeAttr('disabled');
            });
        return false;
    };


    var getForm = () => {
        $btn.attr('disabled','disabled');

        axios.get(_link)
            .then((response) => {
                Swal.fire({
                    html: response.data,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
            })
            .catch((error) => {
                window.showErrors(error.response.data);
            })
            .then(() => {
                $btn.removeAttr('disabled');
            });
    };

    return {
        init: () => {
            start();
        }
    }
})();
