/**
 * Allows you to add data-method="METHOD to links to automatically inject a form
 * with the method on click
 *
 * Example: <a href="{{route('customers.destroy', $customer->id)}}"
 * data-method="delete" name="delete_item">Delete</a>
 *
 * Injects a form with that's fired on click of the link with a DELETE request.
 * Good because you don't have to dirty your HTML with delete forms everywhere.
 */
function addDeleteForms() {
    $('[data-method]').append(function () {
          if (! $(this).find('form').length > 0)
              return "\n" +
                "<form action='" + $(this).attr('href') + "' method='POST' name='delete_item' style='display:none'>\n" +
                "<input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                "<input type='hidden' name='_token' value='" + $('meta[name="csrf-token"]').attr('content') + "'>\n" +
                "</form>\n";
          else
              return "";
      })
      .attr('href','javascript:void(0);')
      .attr('style', 'cursor:pointer;')
      .attr('onclick', '$(this).find("form").submit();');
}

/**
 * Place any jQuery/helper plugins in here.
 */
$(function(){
    $('.select2-multiple').select2({
        width: '100%'
    });
    $('.select2-single').select2({
        width: '100%'
    });

    /**
     * Add the data-method="delete" forms to all delete links
     */
    addDeleteForms();

    /**
     * Disable all submit buttons once clicked
     */
    $('form').submit(function() {
        $(this).find('input[type="submit"]').attr("disabled", true);
        $(this).find('button[type="submit"]').attr("disabled", true);
        return true;
    });

    /**
     * Bind all bootstrap tooltips & popovers
     */
    $('body').tooltip({
        selector: '[data-toggle="tooltip"]'
    });
    // $("[data-toggle='tooltip']").tooltip();


    /**
     * Generic confirm form delete using Sweet Alert
     */

    $('body').on('click', '[data-method-ajax="delete"]', function(e){
        e.preventDefault();
        let form = this,
          $this = $(this),
          link = $('a[data-method-ajax="delete"]'),
          method = (link.attr('data-method-ajax')) ? link.attr('data-method-ajax') : "post",
          cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : "Cancel",
          confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : "Yes, delete",
          title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : "Are you sure you want to delete this item?";

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            type: 'warning'
        }).then((result) => {
            if (!!!result.value) {
                return false;
            }

            axios.post(
              $this.attr('href'),
              {'_method': method, '_token': $('meta[name="csrf-token"]').attr('content')}
              )
              .then(function( response ) {
                  let $prnt = $this.closest('.wrap-ajax-item');
                  if(!$prnt.length){
                      $prnt = $this.closest('tr');
                  }
                  $prnt.fadeOut(500, () => {
                      setTimeout(()=>{
                          $prnt.remove();
                      }, 100);
                  });
              })
              .catch(error => {
                  window.showErrors(error.response);
              });
        });
    }).on('click', '[data-method-ajax="get"]', function(e){
        e.preventDefault();
        let form = this,
          $this = $(this),
          link = $('a[data-method-ajax="get"]'),
          method = (link.attr('data-method-ajax')) ? link.attr('data-method-ajax') : "post",
          cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : "Cancel",
          confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : "Yes, delete",
          title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : "Are you sure you want to delete this item?";

        axios.post(
          $this.attr('href'),
          {'_method': method, '_token': $('meta[name="csrf-token"]').attr('content')}
          )
          .then(function( response ) {
              $this.closest('.wrap-ajax-item').replaceWith(response.data);
          })
          .catch(error => {
              window.showErrors(error.response);
          });
    }).on('click', '[data-method-ajax="post"]', function (e) {
        e.preventDefault();

        const $this = $(this),
          form = $this.parents('form');

        $('#errors').html('');

        axios.post(
          form.attr('action'),
          form.serialize()
          )
          .then(( response ) => {
              $('#wrap-ajax-block').prepend(response.data);
              form.find('[data-ajax-field]').val('');
          })
          .catch((error) => {
              window.showErrors(error.response);
          });
    }).on('click', '[data-method-ajax="patch"]', function (e) {
        e.preventDefault();

        const $this = $(this),
          form = $this.parents('form');

        $('#errors').html('');

        axios.post(
          form.attr('action'),
          form.serialize()
          )
          .then(function( response ) {
              $this.closest('.wrap-ajax-item').replaceWith(response.data);
          })
          .catch(error => {
              window.showErrors(error.response);
          });
    }).on('submit', 'form[name=delete_item]', function(e){
        e.preventDefault();

        let form = this,
          link = $('a[data-method="delete"]'),
          cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : "Cancel",
          confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : "Yes, delete",
          title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : "Are you sure you want to delete this item?";

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            type: 'warning'
        }).then((result) => {
            result.value && form.submit();
        });
    }).on('click', 'a[name=confirm_item]', function(e){
        /**
         * Generic 'are you sure' confirm box
         */
        e.preventDefault();

        let link = $(this),
          title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : "Are you sure you want to do this?",
          cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : "Cancel",
          confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : "Continue";

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            type: 'info'
        }).then((result) => {
            result.value && window.location.assign(link.attr('href'));
        });
    });
});
