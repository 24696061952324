$(function () {
    let $errBlock = $('#errors');
    let timeShow = 3.5 * 1000;

    window.ldrBtn = (() => {
        return {
            s: ($btn) => {
                let $ico = $btn.find('[data-icon]');
                let _ico = $ico.attr('data-icon');
                $btn.attr('data-fa-ico',_ico).attr('disabled','disabled');
                $ico.removeClass('fa-'+_ico).addClass('fa-spinner fa-spin');
                $('.tooltip').remove();
                return _ico;
            },
            h: ($btn) => {
                let _ico = $btn.attr('data-fa-ico') || false;
                $btn.find('[data-icon]').wrap('<span>');
                let $prnt = $btn.find('[data-icon]').parent();
                $btn.find('[data-icon]').removeClass('fa-spinner fa-spin').addClass('fa-check');
                $('.tooltip').remove();
                if(_ico) {
                    $btn.removeAttr('data-fa-ico');
                    setTimeout(function(){
                        $prnt.fadeOut(200, function() {
                            $btn.find('[data-icon]').removeClass('fa-check').addClass('fa-' + _ico);
                            $prnt.fadeIn(200);
                            $btn.removeAttr('disabled');
                        });
                    }, 200);
                }else{
                    $btn.removeAttr('disabled');
                }
            },
        }
    })();

    window.showErrors = (data) => {
        const xhrStatus = (!_.isUndefined(data.status)) ? parseInt(data.status) : 200;
        const errors = (!_.isUndefined(data.responseJSON) && !_.isUndefined(data.responseJSON.errors))
            ? data.responseJSON.errors
            : (
                !_.isUndefined(data.data) && !_.isUndefined(data.data.errors)
                    ? data.data.errors
                    : 'error'
            )
        ;
        const addMess = (errorMessage, status) => {
            let stClass = (_.isUndefined(status)) ? 'danger' : status;
            // let errorOne = `<div class="alert alert-${stClass}" role="alert">${errorMessage}</div>`;
            let errorOne = `<div class="alert alert-${stClass} alert-dismissible fade show" role="alert">
                                ${errorMessage}
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>`;
            $errBlock.append(errorOne);
            setTimeout(() => {
                let $one = $errBlock.find('.alert:last');
                setTimeout(() => {
                    $one.slideUp(400, function () {
                        $one.remove();
                    })
                }, timeShow);
            }, 0);
        };
        if (errors !== 'error') {
            Object.keys(errors).forEach(function (key) {
                addMess(errors[key]);
            });
        } else {
            if(xhrStatus > 500){
                addMess('An error has occurred on the server. Try later!');
            }else if (xhrStatus === 404) {
                addMess('Page not found!');
            }else {
                addMess('Error :(');
            }
        }
    };

    window.showMessage = (message) => {
        if (_.isEmpty(message)) {
            return;
        }

        const addMess = (message, status) => {
            let stClass = (_.isUndefined(status)) ? 'success' : status;
            let messageOne = `<div class="alert alert-${stClass} alert-dismissible fade show" role="alert">
                                ${message}
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>`;

            $errBlock.append(messageOne);
            setTimeout(() => {
                let $one = $errBlock.find('.alert:last');
                console.log($one);

                setTimeout(() => {
                    $one.slideUp(400, function () {
                        $one.remove();
                    })
                }, timeShow);
            }, 0);
        };

        addMess(message);
    };

});
